export const UsersQuotes = [
	{ userName: 'spring_shady', quote: "I don't believe in no-win scenarios." },
	{
		userName: 'Chauncey1',
		quote: "As you move forward on your life's journey,it can most helpful to keep past negative experiences in your rear view mirror and not place them on your windshield, potentially blocking your view of the future."
	},
	{ userName: 'QuoteMaster', quote: 'You cannot judge people because they sin differently than you. - Erykah Badu' },
	{ userName: 'kitkatkelly', quote: 'Be yourself. Everyone else is already taken.' },
	{ userName: 'jyotiswamy', quote: "Become at ease with oneself, become one with one's soul." },
	{ userName: 'J_mcflurry', quote: 'The person you love the most must be yourself, no one is going to stick with you till the very end but you.' },
	{ userName: 'Sniperbd', quote: "It's all in your heart." },
	{ userName: 'Uprophet', quote: 'Your given 1 bullet at life. Your bullet may bring the greatest happinesses or the greatest failures in your path, if one thing for sure, better damn make sure you aim that bullet towards the stars.' },
	{ userName: 'Faisaltheba', quote: 'Friends are the siblings God never gave us.' },
	{ userName: 'theofficial__jenkins', quote: "We ain't picture perfect but we worth the picture still. - J. Cole" },
	{ userName: 'mariela_alexsiaaa', quote: 'So ironic how a person who makes you so strong, can also be your greatest weakness...' },
	{ userName: 'Fresher', quote: 'It is because of the devotion or sacrifice of individuals that causes become of value.' },
	{ userName: 'DeltaKaiser', quote: 'Success is not final, Failure is not fatal: it is the courage to continue that counts. - Winston Churchill' }
];
